import React, { useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Paper,
  TableContainer,
  Box,
  Grid,
  TablePagination,
  Button
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useSelector,useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { chargerLogsData } from "../../slice/ChargerLogsSlice";
import EmptyData from "../EmptyData";
import noChargers from "../../assets/emptyData/noChargers.svg";
import noSessions from "../../assets/emptyData/noSessions.svg";
import noUsers from "../../assets/emptyData/noUsers.svg";
import CustomOptionsModal from "./CustomOptionsModal";
import ChargerTransitions from '../ChargerTransitions';
// import { setChargerId } from "../../slice/ChargerTransitionsSlice";
import CustomDialog from "./CustomDialog";
import GSMIcon from "../../assets/dashBoard/gsm.svg";
import WifiIcon from "../../assets/dashBoard/wifi.svg";
import DynamicFormModal from "./DynamicFormModal";
import CustomToggle from "./CustomToggle";
import { billingDetailsData } from "../../slice/BillingDetailsSlice";
import { userDetailsData } from "../../slice/UserDetailsSlice";
import { setUserId } from "../../slice/EditUserSlice";
import { deleteUserAdminData } from "../../slice/DeleteUserAdminSlice";
import { callDynamicTableApi } from "../../slice/DynamicTableApiCallSlice";
import QRCodeModal from "./QRCodeModal";

const DynamicTable = ({
  setSelectedData,
  selectedData,
  tabValue,
  searchedData,
  tabs,
  onShowLogsChange,
  height,
  maxHeight
}) => {
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [selectedUser, setSelectedUser] = useState();
  // const [options, setOptions] = useState("");
  // const [isTransitionButtonVisible, setIstransitionButtonVisible] = useState(false);
  // const [id, setId] = useState("");
  const [isTransitionModalOpen, setIsTransitionModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDynamicModalOpen, setIsDynamicModalOpen] = useState(false);
  const [dynamicModalType, setDynamicModalType] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [qrUrl, setQrUrl] = useState('');
  const { currency } = useSelector(state => state.user);

  if (
    !tabs ||
    !tabs[tabValue] ||
    !tabs[tabValue].headers ||
    !tabs[tabValue].table_content
  ) {
    return null;
  }

  const tabData = tabs[tabValue];
  const headRows = tabData.headers;
  const tableContent = searchedData;

  const headRowsWithoutEdit = headRows.filter((row) => row.id !== "edit" && row.id !== "id" && row.id !== 'get_configuration');

  if (!tabData || !headRows || !tableContent) {
    return null;
  }

  const handleEditFormOpen = () => {
    if (tabData.name === "Chargers") {
      const newApiCallType = "editCharger";
      localStorage.setItem("dynamicModalType", newApiCallType);
      setDynamicModalType(newApiCallType);
      setIsModalOpen(false);
      setIsDynamicModalOpen(true);

    } else if (tabData.name === "Users") {
      const newApiCallType = "editUser";
      localStorage.setItem("dynamicModalType", newApiCallType);
      setDynamicModalType(newApiCallType);
      setIsModalOpen(false);
      setIsDynamicModalOpen(true);
    }
    else if (tabData.name === "Admins") {
      const newApiCallType = "editAdmin";
      localStorage.setItem("dynamicModalType", newApiCallType);
      setDynamicModalType(newApiCallType);
      setIsModalOpen(false);
      setIsDynamicModalOpen(true);
    }
  };

  const handleGetConfigurationFormOpen = () => {
    const newApiCallType = "getConfiguration";
    localStorage.setItem("dynamicModalType", newApiCallType);
    setDynamicModalType(newApiCallType);
    setIsModalOpen(false);
    setIsDynamicModalOpen(true);
  }

  // const handleTransitionModalOpen = () => {
  //   dispatch(setChargerId(selectedData?.charger_id));
  //   setIsModalOpen(false);
  //   setIsTransitionModalOpen(true);
  //   navigate('#charger-transition', { replace: true });
  // }
  const handleTransitionModalClose = () => {
    setIsTransitionModalOpen(false);
    navigate('/charging-stations', { replace: true });
  }

  // const handleDelete = () => {
  //   handleCloseModal();
  // };

  const handleOpenModal = (charger, event) => {
    setAnchorEl(event.currentTarget);
    setSelectedData(charger);
    setSelectedUser(charger);
    dispatch(setUserId(charger?.id))
    if (window.location.pathname === "/user-management") {
      dispatch(setUserId(charger?.name?.id))
    }
    setIsModalOpen(true);
    // if (tabData.name === "Chargers") {
    //   setIstransitionButtonVisible(true);
    //   setOptions("Charger Options");
    //   setId(`Charger ID: ${charger?.charger_id}`);
    // } else if (tabData.name === "Users") {
    //   setIstransitionButtonVisible(false);
    //   setOptions("User Options");
    //   setId(`User ID: ${charger?.id}`);
    // }
    // else if (tabData.name === "Admins") {
    //   setIstransitionButtonVisible(false);
    //   setOptions("Admin Options");
    //   setId(`Admin ID: ${charger?.id}`);
    // }
  };

  const handleCloseModal = () => {
    setSelectedData(null);
    setSelectedUser(null);
    setIsModalOpen(false);
  };

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = Array.isArray(searchedData)
    ? [...searchedData]
    : [];

  sortedData.sort((a, b) => {
    const aValue = a[orderBy];
    const bValue = b[orderBy];

    if (typeof aValue === "string" && typeof bValue === "string") {
      return order === "asc"
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    } else {
      return order === "asc" ? aValue - bValue : bValue - aValue;
    }
  });

  const renderEmptyData = () => {
    const currentTabData = tabs[tabValue];
    if (currentTabData) {
      const { name } = currentTabData;
      let imagePath;

      switch (name) {
        case "Chargers":
          imagePath = noChargers;
          break;
        case "Sessions":
          imagePath = noSessions;
          break;
        case "Users":
          imagePath = noUsers;
          break;
        default:
          imagePath = noUsers;
          break;
      }

      return <EmptyData imagePath={imagePath} message={`No ${name} Found`} />;
    }
    return null;
  };

  if (sortedData.length === 0) {
    return renderEmptyData();
  }

  const handleQRClick = (charger_id, id) => {
    const baseUrl = process.env.REACT_APP_WEBAPP_URL;
    const qrUrl = baseUrl + "charger/" + charger_id + "__" + id
    setQrUrl(qrUrl);
    setModalOpen(true);
  }
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleNameClick = (charger) => {
    if (charger) {
      setSelectedData(charger);
      if (window.location.pathname === "/billing-payment") {
        dispatch(billingDetailsData(charger.api))
      }
      else if (window.location.pathname === "/charging-stations") {
        dispatch(chargerLogsData(charger.api));
      }
      else if (window.location.pathname === "/user-management") {
        dispatch(userDetailsData(charger.api));
      }
      onShowLogsChange(true);
    }
  };

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const confirmMessageHandler = (api) => {
    window.confirm('Are you confirm?') && dispatch(callDynamicTableApi(api))
  }

  const renderCellContent = (content, columnId) => {
    if (!content) {
      return "";
    }
    if (columnId === "charger_name") {
      const { label, api } = content;
      if (label && api) {
        return (
          <Grid
            onClick={() => handleNameClick(content)}
            style={{ color: "blue", cursor: "pointer" }}
          >
            {label}
          </Grid>
        );
      }
    }
    if (columnId === "qr_code") {
      const { charger_id, connectors_count } = content;
      if (charger_id && connectors_count) {
        return (
          <>
            {Array.from({ length: Number(connectors_count) }).map((_, i) => (
              <Grid
                key={i}
                onClick={() => handleQRClick(charger_id, i + 1)}
                style={{ color: "blue", cursor: "pointer" }}
              >
                View QR {i + 1}
              </Grid>
            ))}
          </>
        );
      }
    }

    else if (columnId === "name" && typeof content === 'object') {
      const { label, api } = content || {};
      if (label && api) {
        return (
          <Grid
            onClick={() => handleNameClick(content)}
            style={{ color: "blue", cursor: "pointer" }}
          >
            {label}
          </Grid>
        );
      }
      else {
        return null;
      }
    }
    else if (columnId === "invoice_id") {
      const { label, api } = content || {};
      if (label && api) {
        return (
          <Grid
            onClick={() => handleNameClick(content)}
            style={{ color: "blue", cursor: "pointer" }}
          >
            {label}
          </Grid>
        );
      }
    }
    else if (columnId === "deactivate" || columnId === 'is_active') {
      const { api, value } = content;
      return (
        <Grid item xs={12}>
          <CustomToggle
            value={value}
            onValueChange={(newValue) => {
              if (newValue !== value) {
                dispatch(deleteUserAdminData(api));
              }
            }}
          />
        </Grid>
      );
    }
    else if (columnId === "unlink" || columnId === "clear_cache" || columnId === "soft_reset" || columnId === "hard_reset") {
      const { label, api, color, bg_color } = content;
      if (label && api) {
        return (
          <Grid item xs={12}>
            <Button variant="text" onClick={() => confirmMessageHandler(api)} style={{ color: color || "blue", cursor: "pointer", border: "1px solid white", background: bg_color || "aliceBlue" }}>
              {label}
            </Button>
          </Grid>
        );
      }
    }   
    else if (columnId === "pay") {
      const { label, api, disabled } = content;
      if (label && api) {
        return (
          <Grid item xs={12}>
            <Button 
            variant="contained" 
            color='success'
            onClick={() => confirmMessageHandler(api)} 
            disabled={disabled}
            >
              {`${label}`}
            </Button>
          </Grid>
        );
      }
    }
    else if (columnId === "login") {
      const { label, api, target } = content;
      if (label && api) {
        return (
          <Grid item xs={12}>
            <a variant="text"
              style={{
                color: "rgb(0,0,255)",
                cursor: "pointer",
                border: "1px solid white",
                background: "aliceBlue",
                padding: '6px 8px',
                textDecoration: "none",
                borderRadius: '1px solid white',
                fontSize: '14px',
                fontWeight: "500",
              }}
              href={api}
              target={target}
            >
              {label}
            </a>
          </Grid>
        );
      }
    }
    else if (columnId === "invoice") {
      const { label, api } = content;
      return (
        <a
          href={api}
          target="_blank"
          rel="noreferrer"
          style={{ color: "blue", cursor: "pointer" }}
        >
          {label}
        </a>
      );
    }
    else if (columnId === "details") {
      const { label, api } = content;
      return (
        <a
          href={api}
          target="_blank"
          rel="noreferrer"
          style={{ color: "blue", cursor: "pointer" }}
        >
          {label}
        </a>
      );
    }
    else if (columnId === "status_") {
      const textColor = content === "Online" ? "#269D72" : "#FF4949";

      return (
        <Grid
          style={{
            color: textColor,
            borderRadius: "48px",
            background: "#FAFAFB",
            display: "flex",
            padding: "2px 8px",
            justifyContent: "center",
          }}
        >
          {content.toString()}
        </Grid>
      );
    } else if (columnId === "network_type") {
      const iconSrc = content === "GSM" ? GSMIcon : content === "WIFI" ? WifiIcon : null;

      return (
        <Grid style={{ display: "flex", alignItems: "center" }}>
          {iconSrc && <img src={iconSrc} alt={`Network Type: ${content}`} style={{ marginRight: "4px" }} />}
          {content.toString()}
        </Grid>
      );
    }

    return content.toString();
  };

  console.log(headRowsWithoutEdit)

  return (
    <>
      <Box
        sx={{
          width: "100%", // Set the width of the parent container to 100%
        }}
      >
        <Box
          style={{ boxShadow: "none" }}
          sx={{
            height: height || "calc(100vh - 265px)",
            width: "100%",
          }}
        >
          <Box
            sx={{
              p: 2,
              boxSizing: "border-box",
              width: "100%",
              display: "table",
              tableLayout: "fixed",
            }}
          >
            <TableContainer
              component={Paper}
              style={{ width: "100%", boxShadow: "none", maxHeight: maxHeight || "470px" }}
            >
              <Table stickyHeader style={{ height: "100%" }}>
                <TableHead>
                  <TableRow>
                    {headRowsWithoutEdit.map((row) => (
                      <TableCell
                        key={row.id}
                        sortDirection={orderBy === row.id ? order : false}
                        style={{
                          fontSize: "12px",
                          fontWeight: "600",
                          whiteSpace: "nowrap",
                          textTransform: "uppercase",
                          position: "sticky",
                          backgroundColor: "#EFF4FF",
                          zIndex: 1,
                          padding: "10px",
                        }}
                      >
                        <TableSortLabel
                          active={orderBy === row.id}
                          direction={orderBy === row.id ? order : "asc"}
                          onClick={() => handleSort(row.id)}
                        >
                          {row.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    {/* Add the last column with 3 dots */}
                    {tabData.headers.find((header) => header.id === "edit") && (
                      <TableCell
                        style={{
                          fontSize: "12px",
                          fontWeight: "600",
                          textTransform: "uppercase",
                          position: "sticky",
                          top: "0",
                          right: "0",
                          backgroundColor: "#EFF4FF",
                          boxShadow: "-4px 0px 8px 0px rgba(152, 162, 179, 0.24)",
                          zIndex: 1,
                          padding: "10px",
                        }}
                      ></TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow key={row.id}>
                        {headRowsWithoutEdit.map((headRow) => (
                          <TableCell key={headRow.id} style={{ padding: "10px" }}>
                            {renderCellContent(row[headRow.id], headRow.id)}
                          </TableCell>
                        ))}
                        {/* Add content for the last column (e.g., 3 dots) */}
                        {tabData.headers.find((header) => header.id === "edit" || header.id === "get_configuration") && (
                          <TableCell
                            style={{
                              fontSize: "12px",
                              fontWeight: "600",
                              textTransform: "uppercase",
                              position: "sticky",
                              right: 0,
                              backgroundColor: "#EFF4FF",
                              boxShadow:
                                "-4px 0px 8px 0px rgba(152, 162, 179, 0.24)",
                              zIndex: 999,
                              padding: "10px",
                            }}
                          >
                            <IconButton
                              sx={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                color: "var(--light-mode-gray-100, #001233)",
                              }}
                              aria-label="options"
                              onClick={(event) => handleOpenModal(row, event)}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <CustomOptionsModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                // options={[]}
                // ID={id}
                anchorEl={anchorEl}
                handleEdit={handleEditFormOpen}
                handleGetConfiguration={headRows?.find(item => item.id === "get_configuration") ? handleGetConfigurationFormOpen : false}
              // handleDelete={handleDelete}
              // showtransitionBtn={isTransitionButtonVisible}
              // handleOpenTransition={handleTransitionModalOpen}
              />
              {tabData.name === "Chargers" ? (
                <CustomDialog
                  isOpen={isTransitionModalOpen}
                  onClose={handleTransitionModalClose}
                  component={<ChargerTransitions />}
                  topPosition="-16%"
                />
              ) : (
                <></>
              )}
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[30, 60, 90]}
              component="div"
              count={tableContent.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Box>
      </Box>
      <DynamicFormModal
        isOpen={isDynamicModalOpen}
        onClose={() => setIsDynamicModalOpen(false)}
        apiCallType={dynamicModalType}
        editTabEndpoint={selectedData?.edit?.api}
        getConfigurationTabEndpoint={selectedData?.get_configuration?.api}
      // editUserEndpoint={selectedUser?.edit?.api}
      />
      <QRCodeModal open={modalOpen} onClose={handleModalClose} url={qrUrl} />
    </>
  );
};

export default DynamicTable;
